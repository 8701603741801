<template>
    <div class="view view--protocole--add">
      <div class="view-header view-header--protocol-grouping"
        v-if="grouping && pageTitle">
        <div class="view-header__footer">
          <BtnBack :to="{ 'name': 'protocolEssaisGroupings' }" />
          <h1 class="page-title">{{pageTitle}}&nbsp;: {{ grouping.nom }}</h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <div class="title-bar margin-bottom">
              <h2>{{ pageTitle }} <span>({{ trials.length }}&nbsp;essais et {{ selectedVariables.length }}&nbsp;variables)</span></h2>
            </div>
            <KeyValue v-if="grouping" label="Nom du regroupement" :value="grouping.nom"/>
            <KeyValue v-if="grouping" label="Type d'analyse" :value="grouping.type === 1 ? 'Rapport sur données brutes' : 'Rapport sur données moyennées'"/>
          </Container>
        </Section>

        <Section>
          <Container>
            <h3>{{ trials?.length }}&nbsp;essai{{ trials?.length > 1 ? 's' : '' }}</h3>
          </Container>
          <List v-if="trials.length" :header="list.headers" :items="list.col" :item-key="list.key" :list-data="trials" disableGroupActions
            disableActions disableCheckbox>

            <template v-slot:culture="{ item }">
              <template v-if="item.protocole.cultures.length > 0">
                <div class="tags">
                  <MiniTag v-for="culture in item.protocole.cultures" :key="culture.id" :id="culture.uid"
                    :text="culture.nom" />
                </div>
              </template>
              <template v-else>
                <span></span>
              </template>
            </template>

            <template v-slot:modalites="{ item }">
              <template v-if="item.modalities">
                {{ item.modalities.length }}
              </template>
            </template>

            <template v-slot:variables="{ item }">
              <template v-if="item.variables">
                {{ item.variables.join(', ') }}
              </template>
            </template>

            <template v-slot:entite="{ item }">
              {{ item.protocole?.entite?.nom }}
            </template>

            <template v-slot:annee="{ item }">
              {{ item.protocole?.annee_recolte?.valeur }}
            </template>

          </List>
        </Section>

        <Section>
          <Container>
            <h3>{{ selectedVariables.length }}&nbsp;variable{{ selectedVariables?.length > 1 ? 's' : '' }}</h3>
            <div class="grid grid--compact capped-width">
              <div class="variable" v-for="(variable, i) in selectedVariables" :key="i">
                {{ variable.designation }}
              </div>
            </div>
          </Container>
        </Section>

      </div>
    </div>

    <NavigationDropdownProtocol />

  </template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import BtnBack from '@/components/base/BtnBack.vue'
import NavigationDropdownProtocol
  from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'
import List from '@/components/list/List.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'ProtocolGroupingAddEditView',

  components: {
    BtnBack,
    Section,
    Container,
    NavigationDropdownProtocol,
    KeyValue,
    List,
    MiniTag,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      trials: [],
      modalities: [],
      variables: [],
      selectedVariables: [],
      grouping: {},
      routeParams: {
        id: this.$route.params.id,
      },
      list: {
        headers: [
          { label: 'Nom de l\'essai' },
          { label: 'Année' },
          { label: 'Culture' },
          { label: 'Entité' },
          { label: 'Modalités' },
          { label: 'Variables' },
        ],
        col: ['nom', 'annee', 'culture', 'entite', 'modalites', 'variables'],
        key: 'id',
      },
    }
  },

  mounted() {
    const { id, rid } = this.$route.params
    this.getGrouping(id, rid)
  },

  methods: {
    alterTrials() {
      this.trials.forEach((trial) => {
      // eslint-disable-next-line
      trial.modalities = this.modalities.filter(modality => {
          return Object.values(modality.essais).some((essai) => essai.id === trial.id && essai.selected === true)
        })
        // eslint-disable-next-line
      trial.variables = this.variables.filter(variable => {
          return variable.essais ? Object.values(variable.essais).some((essai) => essai.id === trial.id && essai.selected === true) : false
        }).map((v) => v.designation)
      })
    },
    setSelectedVariables() {
      return this.variables.filter((variable) => (variable.essais ? Object.values(variable.essais).some((essai) => essai.selected === true) : false))
    },
    getGrouping(protocolId, groupingId) {
      this.emitter.emit('open-loader')
      const endpoint = `protocole/${protocolId}/regroupement_essai/${groupingId}`

      const fetchGrouping = () => {
        this.fetchService.get(endpoint)
          .then((r) => {
            this.grouping = r.data
            this.trials = this.grouping.essais
            this.modalities = this.grouping.modalites
            this.variables = this.grouping.variables
            this.selectedVariables = this.setSelectedVariables()
            this.alterTrials()
          })
          .catch(() => {
            this.emitter.emit('alert', {
              type: 'error',
              content: 'Une erreur s\'est produite lors de la récupération du regroupement.',
            })
          })
          .finally(() => {
            this.emitter.emit('close-loader')
          })
      }

      fetchGrouping()
    },

  },
}
</script>

<style lang="scss" scoped>

h2 {
  > span {
    color: $color-gray;
    font-weight: $font-weight-normal;
  }
}

h3 {
  margin-bottom: $gutter;
}

.variable {
  font-weight: $font-weight-semibold;
}

.capped-width {
  max-width: 60rem;
}

</style>
